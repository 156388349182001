import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanLoad,
  Route,
  CanActivateChild
} from '@angular/router';
import { TaskList } from 'src/app/core/constants/app-constants';
import { AppData } from '../../services/app-data.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad, CanActivateChild {
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (state.url === '/' && !AppData.isAuthenticated) {
      this.router.navigate(['/login']);
      return false;
    }
    TaskList.forEach((taskList, index) => {
      if (state.url.indexOf(taskList.route) >= 0) {
        if (taskList.route === state.url) {
          if (AppData.userData._permissions[taskList.permissionKey]) {
            return true;
          } else {
            this.router.navigate(['/dashboard/home']);
          }
        }
      }

    });
    return true;
  }
  constructor(public router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      if (state.url === '/' && !AppData.isAuthenticated) {
        this.router.navigate(['/login']);
        return false;
      }
      if (AppData.token['_value'] != null) {
        const route = state.url.split('/');

        const declaredRoutes = ['dashboard', 'support', 'sales', 'marketing', 'operations', 'it'];
        if (declaredRoutes.indexOf(route[route.length - (route.length - 1)]) > -1) {
          return true;
        } else {
          this.router.navigate(['/dashboard/home']);
        }

      } else {
        if (state.url === '/login') {
          return true;
        } else {
          this.router.navigate(['/login']);
          return false;
        }

      };
      return true;
    } catch (error) {
      console.log(error);
    }
  }

  canLoad(route: Route) {
    if (AppData.isAuthenticated) {
      return true;
    }
    this.router.navigate(['login']);
    return false;
  }
}
