import { Injectable } from '@angular/core';
import { ApiRequestService } from 'src/app/shared';

@Injectable({
  providedIn: 'root'
})
export class SalesRestaurantDashboardService {

  constructor(
    private apiService: ApiRequestService
  ) { }

  getCityAndDocStatus(type) {
    return this.apiService.get('getFilterAppData', `?type=${type}`)
  }

  getDashboardRestaurantList(body, type) {
    console.log(body);

    if (type === 'agreementDashboard') {

      return this.apiService.post('getAgreementDetailList', body);

    } else if (type === 'onboardingDashboard') {

      return this.apiService.post('getOnboardDetailList', body);

    } else if (type === 'restaurantReviewDashboard') {

      return this.apiService.post('getReviewDetailList', body);

    } else if (type === 'financialDashboard') {

      return this.apiService.get('getCommissionData', '?startDate=' + body.startDate + '&&endDate=' + body.endDate + '&&outletId=' + body.restaurantList)

    }
  }

  updateStatus(body, type) {

    if (type === 'agreementDashboard') {

      return this.apiService.post('updateAgreement', body);

    } else if (type === 'onboardingDashboard') {

      return this.apiService.post('updateOnboard', body);

    } else if (type === 'restaurantReviewDashboard') {

      return this.apiService.post('updateAgreement', body);

    }

  }

  updateOnboardStatus(body, type) {
    if (type === 'Onboarding') {
      return this.apiService.post('updateOnboardStatus', body);
    } else {
      return this.apiService.post('updateReviewStatus', body);
    }
  }

  updateRestReview(body) {
    return this.apiService.post('updateRestReview', body);
  }

  generateXlsForRestList(startDate, endDate, locality, city, status, pendingOnboardStatus, pageName, outletId, completedOnboardingStatus) {

    if (pageName === 'Financial') {
      return this.apiService.get('getCommissionData',
      `?startDate=${startDate}&&endDate=${endDate}&&outletId=${outletId}&download=${true}`,
        { Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }, true)

    } else {
      return this.apiService.get('generateXlsForRestList',
        `?startDate=${startDate}&endDate=${endDate}&locality=${locality}&city=${city}&status=${status}&pendingOnboardStatus=${pendingOnboardStatus}&sendMail=false&pageName=${pageName}&completedOnboardingStatus=${completedOnboardingStatus}`,
        { Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }, true);
    }
  }

  reject(body, type) {
    if (type === 'Agreement') {
      return this.apiService.post('cancelAgreement', body);
    } else if (type === 'Onboarding') {
      return this.apiService.post('cancelOnboard', body);
    } else {
      return this.apiService.post('cancelReview', body);
    }
  }

  getOutletList(city) {
    return this.apiService.get('getOutletList', `?city=${city}`);
  }

  assignMenuEntryPerson(objData) {
    return this.apiService.post('assignMenuEntryPerson', objData);
  }
}
