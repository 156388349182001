import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiRequestService } from './shared/services/api-request.service';
import { CommonService } from './shared/services/common.service';
import { ToastrModule } from 'ngx-toastr';
import { TagInputModule } from 'ngx-chips';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StorageService } from './core/services/storage/storage.service';
import { AuthService } from './core/services/auth.service';
import { TokenInterceptor, ResponseInterceptor } from './shared';
import { AppData } from './shared/index';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown-angular7';
import { NgxSortableModule } from 'ngx-sortable';

@NgModule({
  declarations: [
    AppComponent,
     ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgbModule,
    ToastrModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    SharedModule,
    ReactiveFormsModule,
    NgxSortableModule,
    TagInputModule
  ],
  providers: [
    ApiRequestService,
    CommonService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true
    },
    StorageService,
    AuthService,
    NgbActiveModal,

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
