import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-dialog-box',
  templateUrl: './confirm-dialog-box.component.html',
  styleUrls: ['./confirm-dialog-box.component.scss']
})
export class ConfirmDialogBoxComponent implements OnInit {
  @Output() emitData: EventEmitter<any> = new EventEmitter();
  @Input() public confirmText;
  @Input() public restaurantName;
  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
  }

  yes() {
    this.emitData.emit('OK');
  }

  cancel() {
    this.activeModal.close();
  }
}
