import { Injectable } from '@angular/core';
import { ApiRequestService } from '..';

@Injectable({
	providedIn: 'root'
})
export class OtpService {

	constructor(private apiService: ApiRequestService) {
	}

	_login(reqBody) {
		return this.apiService.post('login', reqBody);
	}
	_signUp(reqBody) {
		return this.apiService.post('signup', reqBody);
	}
	_sendOtp(reqBody) {
		return this.apiService.post('sendOtp', reqBody);
	}
}
