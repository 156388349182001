import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { Observable, merge, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { OutletListAutoCompService } from '../../services/outlet-list-auto-comp.service';
import { AppData } from '../../services/app-data.service';

@Component({
  selector: 'app-outlet-list-auto-complete',
  templateUrl: './outlet-list-auto-complete.component.html',
  styleUrls: ['./outlet-list-auto-complete.component.scss']
})
export class OutletListAutoCompleteComponent implements OnInit {
  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  @Input() status;
  @Output() outletIdEvent = new EventEmitter<string>();
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  outletName: any = [];
  outletList;
  outletNameModel: string;
  constructor(private outletListAutoCompService: OutletListAutoCompService) {
    AppData.outletNameClear.subscribe((data) => {
      if (data === 'clear') {
        this.outletNameModel = '';
        this.outletIdEvent.emit('');
      }
    })
  }

  ngOnInit() {
    this.getOutletIdData();
    //  this.outletIdEvent.emit('');
  }

  //for converts a text values from the <input> element to the  array of items to display in the typeahead popup
  search = (text$: Observable<string>) => {
    console.log("instance",this.instance)
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.outletList
        : this.outletList.filter(v => v.name ? v.name.toLowerCase().indexOf(term.toLowerCase()) > -1 : '')).slice(0, this.outletList.length))
    );
  }

  // for convert an item from the result list to a string to display in the input field.
  formatter = (x: { name: string }) => x.name;

  // for emitting selected outlet from the list
  getOutlet(event) {
    console.log("emit");
    console.log(event.item.outletId)
    this.outletIdEvent.emit(event.item.outletId)
  }

  getOutletIdData() {
    this.outletListAutoCompService._getOutletIdName(this.status)
      .then((data) => {
        console.log('data=====>',data);
        this.outletList = data['body'].data.data.outletData;
        console.log('outletList====>',this.outletList)
      }).catch((err) => {
        console.log(err);
      });
  }
  onChange(event) {
    console.log(event);
    if (event.target.value === '') {
      this.outletIdEvent.emit('');
    }
  }
}
