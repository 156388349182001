export const INTERCEPTOR_SKIP_HEADER = 'X-Skip-Interceptor';
// Timer const used for OTP sending
export const COUNTER = 2;
export const SECONDS = 59;
export const PERCENTAGE = 35;
export const OUTLET_FORM_STATUS = 'complete';
export const AVOID_SPACE = '^[a-zA-Z0-9]+(?:(?:\. |[’ ])[a-zA-Z0-9]+)*$';
export const REST_NAME_PATTERN = '^[a-zA-Z0-9]+(?:(?:\. |[’\' ])[a-zA-Z0-9]+)*$';
export const ONLY_CHARACTERS = '^[a-zA-Z]+(?:(?:\. |[’ ])[a-zA-Z]+)*$';
// tslint:disable-next-line: quoteMark
export const AVOID_SPACE_ONLY = "^[a-zA-Z0-9!-/:-@Z-`|]+(?:(?:\. |[' ])[a-zA-Z0-9!-/:-@Z-`|]+)*$";
// tslint:disable-next-line: max-line-length
export const EMAIL_PATTERN = /^(([^<>!#$%&\^\*()\[\]\\.,;:\s@'"?`~]+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const EXCEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel';
export const ZIP = '.zip';
// export const PREPARATION_TIME = 2000;
export const INTERVAL_TIME_ONE_MIN = 1000 * 60;

export const TermConditionList = ["Image clarity should be good.",
  "Image should not contain any Brand's logo / Watermark.",
  "Image should be relevant to food item.",
  "Click on Skip if No Image"
];

export const WEEKDAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const ALLOWED_CERTIFICATE_TYPE = [
  'image/png',
  'image/PNG',
  'image/jpg',
  'image/JPG',
  'image/jpeg',
  'image/JPEG',
  'application/pdf',
];

export const ErrorMessage = {
  startDateError: 'Please check start date is > = the today\'s date',
  selectStartDateMessage: 'Please select the start date',
  selectEndDateMessage: 'Please select the end date',
  checkEndAndStartDate: 'Please check end date is > = the start date',
  checkStartAndCurrentTime: 'Please check start time is > the current time',
  checkEndAndStartTime: 'Please check start time is < the end time',
  maximumTwoMBAllowed: 'Maximum file 2 mb allowed.',
  allowedOnlyExcel: 'Only excel allowed.',
  fillAllDetails: 'Please mention all the details'
};

export const TaskList = [
  // Support
  { displayName: 'Active Offer', route: '/support/home/activeOffers', permissionKey: 'offers', background: '#eeccff', labelFor: "Support" },
  { displayName: 'Agreement Panel', route: `/support/home/sales-restaurant-dashboard/${'agreementDashboard'}`, permissionKey: 'agreementDashboard', background: '#eeccff', labelFor: "Support" },
  { displayName: 'Audit Update', route: '/support/home/auditUpdate', permissionKey: 'auditUpdate', background: '#eeccff', labelFor: "Support" },
  { displayName: 'Assign Account Manager', route: '/support/home/accountManageMap', permissionKey: 'accountManageMap', background: '#eeccff', labelFor: "Support" },
  { displayName: 'Bank Details', route: '/support/home/bank-details', permissionKey: 'bankDetailUpdate', background: '#eeccff', labelFor: "Support" },
  { displayName: 'Cancel Refund', route: '/support/home/cancel-refund', permissionKey: 'cancelRefund', background: '#eeccff', labelFor: "Support" },
  { displayName: 'Commercial Update', route: '/support/home/commercial-update', permissionKey: 'commercialUpdate', background: '#eeccff', labelFor: "Support" },
  { displayName: 'Delivery Operations', route: '/support/home/deliveryOperations', permissionKey: 'deliveryOperations', background: '#eeccff', labelFor: "Support" },
  { displayName: 'Kshetra Process', route: '/support/home/kshetraProcess', permissionKey: 'kshetraProcess', background: '#eeccff', labelFor: "Support" },
  { displayName: 'Make Complete Order', route: '/support/home/mark-complete-order', permissionKey: 'markCompleteOrder', background: '#eeccff', labelFor: "Support" },
  { displayName: 'Menu Deletion', route: '/support/home/menuDeletion', permissionKey: 'menuDelete', background: '#eeccff', labelFor: "Support" },
  { displayName: 'Third Party Menu Clone', route: '/support/home/menuCreation', permissionKey: 'thirdPartyMenuClone', background: '#eeccff', labelFor: "Support" },
  { displayName: 'Menu Support', route: '/support/home/menuSupport', permissionKey: 'menuSupport', background: '#eeccff', labelFor: "Support" },
  { displayName: 'Outlet on maps', route: '/support/home/outletsMapView', permissionKey: 'allOutlet', background: '#eeccff', labelFor: "Support" },
  { displayName: 'Outlet heat map', route: '/support/home/outletHeatMap', permissionKey: 'outletHeatMap', background: '#eeccff', labelFor: "Support" },
  { displayName: 'Outlet availability', route: '/support/home/outletAvailability', permissionKey: 'outletAvailability', background: '#eeccff', labelFor: "Support" },
  { displayName: 'Outlet Go Live', route: '/support/home/outletGoLive', permissionKey: 'outletGoLive', background: '#eeccff', labelFor: "Support" },
  { displayName: 'Outlet Communication Tracker', route: '/support/home/outlet-communication-tracker', permissionKey: 'outletCommunication', background: '#eeccff', labelFor: "Support" },
  { displayName: 'Orders Status', route: '/support/home/orderStatus', permissionKey: 'orderStatus', background: '#eeccff', labelFor: "Support" },
  { displayName: 'Order Breakup', route: '/support/home/orderBreakup', permissionKey: 'orderBreakup', background: '#eeccff', labelFor: "Support" },
  { displayName: 'Onboarding Panel', route: `/support/home/sales-restaurant-dashboard/${'onboardingDashboard'}`, permissionKey: 'onboardingDashboard', background: '#eeccff', labelFor: "Support" },
  { displayName: 'Restaurant status', route: '/support/home/restaurantStatus', permissionKey: 'restStatus', background: '#eeccff', labelFor: "Support" },
  { displayName: 'Restaurant Details', route: '/support/home/rest-details', permissionKey: 'restDetails', background: '#eeccff', labelFor: "Support" },
  { displayName: 'Restaurant Outlet Count', route: '/support/home/restaurantOutletCount', permissionKey: 'restaurantOutletCount', background: '#eeccff', labelFor: "Support" },
  { displayName: 'Review Panel', route: `/support/home/sales-restaurant-dashboard/${'restaurantReviewDashboard'}`, permissionKey: 'restaurantReviewDashboard', background: '#eeccff', labelFor: "Support" },
  { displayName: 'Replicate Menu Items', route: '/dashboard/home/replicateMenuItem', permissionKey: 'replicateMenuItem', background: '#eeccff', labelFor: "Support" },
  { displayName: 'Settlement Report', route: '/support/home/settlementReport', permissionKey: 'settlementReport', background: '#eeccff', labelFor: "Support" },
  { displayName: 'Wallet Credit', route: '/support/home/wallet-credit', permissionKey: 'walletCredit', background: '#eeccff', labelFor: "Support" },
  // Sales
  { displayName: 'Payment Link Genration', route: '/sales/home/paymentLinkGenration', permissionKey: 'paymentLinkCreation', background: '#DB7093', labelFor: "Sales" },
  { displayName: 'Restaurant Status Tracker', route: '/sales/home/salesRestaurantList', permissionKey: 'salesRestaurantList', background: '#DB7093', labelFor: "Sales" },
  { displayName: 'Sales CRM', route: '/dashboard/home/visit', permissionKey: 'visitPlanner', background: '#DB7093', labelFor: "Sales" },
  // Marketing
  { displayName: 'Coupon Creation', route: '/marketing/home/coupon-creation', permissionKey: 'couponCreation', background: '#B0C4DE', labelFor: "Marketing" },
  { displayName: 'Coupon List', route: '/marketing/home/coupon-list', permissionKey: 'couponList', background: '#B0C4DE', labelFor: "Marketing" },
  { displayName: 'Themes Creation', route: '/marketing/home/promotion', permissionKey: 'promotionCreation', background: '#B0C4DE', labelFor: "Marketing" },
  { displayName: 'Themes List', route: '/marketing/home/promotionList', permissionKey: 'promotionList', background: '#B0C4DE', labelFor: "Marketing" },
  { displayName: 'Outlet Promotion Updates', route: '/marketing/home/outletPromotionUpdates', permissionKey: 'outletPromotionUpdates', background: '#B0C4DE', labelFor: "Marketing" },
  { displayName: 'Scratch Card Summ', route: '/marketing/home/scratchCardSumm', permissionKey: 'scratchCardSumm', background: '#B0C4DE', labelFor: "Marketing" },
  { displayName: 'User Signup', route: '/marketing/home/userSignupStatus', permissionKey: 'userSignupStatus', background: '#B0C4DE', labelFor: "Marketing" },
  { displayName: 'User Activity Status', route: '/marketing/home/userActivityStatus', permissionKey: 'userActivityStatus', background: '#B0C4DE', labelFor: "Marketing" },

  // Operations
  { displayName: 'Delivery Priority', route: '/operations/home/delivery-priority', permissionKey: 'deliveryPriority', background: '#9370DB', labelFor: "Operations" },
  { displayName: 'Order Heat Map', route: '/operations/home/orderHeatMap', permissionKey: 'orderHeatMap', background: '#9370DB', labelFor: "Operations" },
  // IT
  { displayName: 'Account Migrate', route: '/it/home/accountMigrate', permissionKey: 'accountMigrate', background: '#8FBC8F', labelFor: "IT" },
  { displayName: 'Outlet Image Processing', route: '/it/home/outletImage', permissionKey: 'couponList', background: '#8FBC8F', labelFor: "IT" },
  { displayName: 'Outlet Validation', route: '/it/home/outletValidation', permissionKey: 'outletValidation', background: '#8FBC8F', labelFor: "IT" },
  // Common
  { displayName: 'Image Captcha Contest', route: '/dashboard/home/imageValidate', permissionKey: 'imageValidation', background: '#FAEBD7', labelFor: "Common" },
  // Accountant
  { displayName: 'Financial Panel', route: `/support/home/sales-restaurant-dashboard/${'financialDashboard'}`, permissionKey: 'financialDashboard', background: '#D8BFD8', labelFor: "Accountant" },
  // { displayName: 'Delivery Report', route: '/dashboard/home/deliveryReport', permissionKey: 'deliveryReport', background: '' },
 

];

export const AllowedExcelFormat = [
  'xlsx',
  'xls',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];

export const promotionList = [
  { displayName: 'Multiple MenuItems', value: "item", key: "many", _value: 'dishes' },
  { displayName: 'Single MenuItem', value: "item", key: "single", _value: 'dish' },
  { displayName: 'Multiple Outlets', value: "outlet", key: "many", _value: 'restaurants' },
  { displayName: 'Single Outlet', value: "outlet", key: "single", _value: 'restaurant' },
];
export const promotionLayoutList = [
  { displayName: 'R-D1', value: 'R-D1' },
  { displayName: 'R-D2', value: 'R-D2' },
  { displayName: 'R-D3', value: 'R-D3' },
  { displayName: 'R-D4', value: 'R-D4' },
  { displayName: 'R-D5', value: 'R-D5' },
  { displayName: 'R-D6', value: 'R-D6' },
  { displayName: 'R-D7', value: 'R-D7' },
  { displayName: 'D-D1', value: 'D-D1' },
  { displayName: 'D-D2', value: 'D-D2' },
  { displayName: 'D-D3', value: 'D-D3' },
  { displayName: 'D-D4', value: 'D-D4' },
  { displayName: 'D-D5', value: 'D-D5' },
  { displayName: 'D-D6', value: 'D-D6' },
  { displayName: 'C-D1', value: 'C-D1' },
  { displayName: 'C-D2', value: 'C-D2' },
  { displayName: 'G-D1', value: 'G-D1' },
  { displayName: 'G-D2', value: 'G-D2' },
  { displayName: 'Banner', value: 'banner' },
  { displayName: 'Banner-1000', value: '1000' },
  { displayName: 'Offer/Ads (Single)-1001', value: '1001' },
  { displayName: 'Top Brands-1002', value: '1002' },
  { displayName: 'Offer or Ads (scroll)-1003', value: '1003' },
  { displayName: 'Popular Cuisines-1004', value: '1004' },
  { displayName: 'Top Restaurants-2001', value: '2001' },
  { displayName: 'New Launched-2002', value: '2002' },
  { displayName: 'Yumzy Exclusive-2003', value: '2003' },
  { displayName: 'Trending Restaurants-2004', value: '2004' },
  { displayName: 'Featured Brands (tinder card)-2005', value: '2005' },
  { displayName: 'Yumzy Best-3001', value: '3001' },
  { displayName: 'Winter Special-3002', value: '3002' },
  { displayName: 'Popular Food (add to plate)-3003', value: '3003' },
  { displayName: 'Treading Foods-3004', value: '3004' },

  // { displayName: 'Tile', value: 'trending' },
  // { displayName: 'Circle', value: 'restaurant_brand_circle' },
  // { displayName: 'Dish with without price', value: 'dish_with_without_price' },
  // { displayName: 'Restaurant with location', value: 'restaurant_with_location' },
  // { displayName: 'Restaurant with offer description', value: 'restaurant_with_offer_description' }
]

export const VERSION_LAYOUT = {
  'R-D1': 'v1',
  'R-D2': 'v1',
  'R-D3': 'v1',
  'R-D4': 'v1',
  'R-D5': 'v1',
  'R-D6': 'v1',
  'R-D7': 'v1',
  'D-D1': 'v1',
  'D-D2': 'v1',
  'D-D3': 'v1',
  'D-D4': 'v1',
  'D-D5': 'v1',
  'D-D6': 'v1',
  'C-D1': 'v1',
  'C-D2': 'v1',
  'G-D1': 'v1',
  'G-D2': 'v1',
  'banner': 'v1',
  '1000': 'v2',
  '1001': 'v2',
  '1002': 'v2',
  '1003': 'v2',
  '1004': 'v2',
  '2001': 'v2',
  '2002': 'v2',
  '2003': 'v2',
  '2004': 'v2',
  '2005': 'v2',
  '3001': 'v2',
  '3002': 'v2',
  '3003': 'v2',
  '3004': 'v2',
};

export const typeList = [
  // { displayName: 'Outlet', value: 'outlet' },
  { displayName: ' Users', value: 'forYou' },
  { displayName: 'All Users', value: 'forAll' },
  // { displayName: 'Inviter', value: 'forReferring' },
  // { displayName: 'Invitee', value: 'usingReferral' }

]

export const DiscountTypeList = [
  { displayName: 'Percentage', value: 'percent' },
  { displayName: 'Net', value: 'net' },
];

export const IntervalUnitList = [
  { displayName: 'Days', value: 'days' },
  { displayName: 'Month', value: 'months' },
  { displayName: 'Hours', value: 'hours' },
  { displayName: 'Once', value: 'once' },
];
export const TOOLTIP_OPTIONS = {
  placement: 'top',
  'show-delay': 500,
  theme: 'light',
};

export const REST_VISIT_PLANNER = [
  // { name: "Get Today's Schedule", icon: "assets/images/Get_today's_schedule.png" },
  // { name: "Today's Schedule", icon: "assets/images/today's_schedule_restaurant.png" },
  // { name: "Update Visit Status", icon: "assets/images/update_visit_status.png" },
  // { name: "Add new restaurant", icon: "assets/images/add_new_restaurant.png" },

  { name: "Add Restaurant", icon: "assets/images/Get_today's_schedule.png" },
  { name: "View Details", icon: "assets/images/search.png" },

];

export const KSHETRA_PROCESS = [
  { name: "Update Kshetra" },
  { name: "View Kshetra Status" },
];

export const HOURS = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
export const MINS = ['00', '15', '30', '45'];
export const timeDataList = [];

export const agreementGuideLines = [
  "Agreement completion TAT is 2 days.",
  "Agreement should be sent for signing on same day when it received."
]

export const onboardingGuideLines = [
  "On-boarding completion TAT is 3 days.",
  "Whoever mark last status as completed should mark on-boarding completed."
]

export const COMMISSION_SCHEMES = [
  {
    name: "Scheme 1",
    value: "scheme1",
    description: "only Commission %age on Subtotal (All inclusive)"
  },
  {
    name: "Scheme 2",
    value: "scheme2",
    description: "only Commission %age on (Subtotal + GST) (All inclusive)"
  },
  {
    name: "Scheme 3",
    value: "scheme3",
    description: "Commission %age + Payment gateway on SubTotal(No GST on comissions)"
  },
  {
    name: "Scheme 4",
    value: "scheme4",
    description: "Commission %age + Payment gateway on (SubTotal + GST)(No GST on comissions)"
  },
  {
    name: "Scheme 5",
    value: "scheme5",
    description: "Commission %age + Payment gateway on SubTotal"
  },
  {
    name: "Scheme 6",
    value: "scheme6",
    description: "Commission %age + Payment gateway on (SubTotal+GST)"
  },
];
export const ADDRESS_BY_CITY = {
  types: [],
  componentRestrictions: { country: 'IN' },
  fields: ['address_components', 'geometry', 'place_id'],
};

