import { Injectable } from '@angular/core';
import { ApiRequestService } from './api-request.service';


@Injectable({
  providedIn: 'root'
})
export class OutletListAutoCompService {

  constructor(private apiService: ApiRequestService) { }
  _getOutletIdName(status) {
    return this.apiService.get('outletIdName', '?status=' + status);
  }
}
