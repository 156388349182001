import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
// tslint:disable-next-line: max-line-length
import { SalesRestaurantDashboardService } from 'src/app/modules/support/sales-restaurant-dashboard/service/sales-restaurant-dashboard.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-menu-entry-person-list',
  templateUrl: './menu-entry-person-list.component.html',
  styleUrls: ['./menu-entry-person-list.component.scss']
})
export class MenuEntryPersonListComponent implements OnInit {
  @Input() public menuEntryPersonList;
  @Input() public restId;
  @Input() public restName;
  @Output() emitData: EventEmitter<any> = new EventEmitter();
  menuEntryPersonNameList;
  name = '';
  optionsMap = {};
  menuEntryForm: any;
  constructor(
    public activeModal: NgbActiveModal,
    private toastService: ToastService,
    private dashboardService: SalesRestaurantDashboardService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.menuEntryPersonNameList = this.menuEntryPersonList;
    this.menuEntryForm = this.formBuilder.group({
      name: ['', Validators.required],
    });
  }
  close() {
    this.activeModal.close();
  }

  submit() {
    const objData = {
      restId: this.restId,
      restName: this.restName,
      menuEntryPersonName: '',
    };
    // console.log(this.restName);
    if (this.menuEntryForm.value.name === '') {
      console.log('please select value');
      this.toastService.presentToast('Please Select Person Name ', '', 'error');

      return;
    }
    objData.menuEntryPersonName = this.menuEntryForm.value.name;
    this.dashboardService
      .assignMenuEntryPerson(objData)
      .then((res) => {
        this.activeModal.close();
        this.emitData.emit('triggered');
      });
  }

}
