import { CustomDirectiveModule } from './utils/custom-directives/custom-directive.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { OutletListAutoCompleteComponent } from './component/outlet-list-auto-complete/outlet-list-auto-complete.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { RestaurantListComponent } from './component/restaurant-list/restaurant-list.component';

import { OtpComponent } from './modals/otp/otp.component';
import { OnboardingStatusComponent } from './modals/onboarding-status/onboarding-status.component';
import { ConfirmDialogBoxComponent } from './modals/confirm-dialog-box/confirm-dialog-box.component';
import { RouterModule } from '@angular/router';
import { HomeComponent } from './component/home/home.component';
import { MenuEntryPersonListComponent } from './modals/menu-entry-person-list/menu-entry-person-list.component';
import { OutletSearchComponent } from './component/outlet-search/outlet-search.component';

@NgModule({
  declarations: [
    OutletListAutoCompleteComponent,
    RestaurantListComponent,
    OtpComponent,
    OnboardingStatusComponent,
    ConfirmDialogBoxComponent,
    HomeComponent,
    MenuEntryPersonListComponent,
    OutletSearchComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbModule,
    FormsModule,
    RouterModule,
    CustomDirectiveModule

  ],
  entryComponents: [
    OtpComponent,
    OnboardingStatusComponent,
    ConfirmDialogBoxComponent,
    MenuEntryPersonListComponent,
    OutletSearchComponent
  ],
  exports: [
    OutletListAutoCompleteComponent,
    OtpComponent,
    RestaurantListComponent,
    OnboardingStatusComponent,
    ConfirmDialogBoxComponent,
    MenuEntryPersonListComponent,
    OutletSearchComponent
  ]
})
export class SharedModule { }
