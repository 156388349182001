import { ToastService } from 'src/app/shared/services/toast.service';
import { OutletSearchService } from './../service/outlet-search.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-outlet-search',
  templateUrl: './outlet-search.component.html',
  styleUrls: ['./outlet-search.component.scss']
})
export class OutletSearchComponent implements OnInit {
  @Output() onOutlet: EventEmitter<any> = new EventEmitter<any>();
  @Output() isOutletsAvailable: EventEmitter<boolean> = new EventEmitter<boolean>();

  outletList = [];
  isOutletAvailable: boolean;
  text = '';
  outletId;
  isLoading = false;
  constructor(private outletService: OutletSearchService, private toast: ToastService) { }

  ngOnInit() {

  }

  onSearchOutlet(txt) {
    this.outletList = [];
    this.isOutletAvailable = false;
    this.isOutletsAvailable.emit(false);
    if (txt != '' && txt != undefined) {
      this.isLoading = true;
      this.outletService.getSearchOutlet(txt).then((res) => {
        const responseData = res['body']['data'];
        this.outletList = responseData.data;
        console.log(this.outletList);
        this.isLoading = false;

        if (this.outletList.length > 0) {
          this.isOutletAvailable = true;
          this.isOutletsAvailable.emit(true);
        } else {
          this.isOutletAvailable = false;
          this.isOutletsAvailable.emit(false);
          this.toast.presentToast('Outlet is not available', 'Error', 'error');
        }
      }).catch((err) => {
        console.log(err);
        this.isLoading = false;
      });
    } else {
      this.toast.presentToast('Please fill outlet name', 'Error', 'error');
    }
  }

  onOutletChange(outletId) {
    if (outletId) {
      const obj = this.outletList.find(itm => itm.outletId === outletId);
      const emitObj = {
        outletId: obj.outletId,
        outletName: obj.outletName
      }
      this.onOutlet.emit(emitObj);
    } else {
      const emitObj = {
        outletId: '',
        outletName: ''
      }
      this.onOutlet.emit(emitObj);
    }
  }

}
