export const environment = {
  production: false,
  imageUrl: 'https://dev.partner.yumzy.ai/fetchimagedetails',
  Url: 'https://imagemapper.yumzy.ai',
  // baseUrl: 'https://devsupport.yumzy.ai/',
  baseUrl: 'https://common.laalsa.com/ypanel',
  // baseUrl: 'https://api.common.laalsa.com/ypanel',
  manualBaseUrl: 'https://common.laalsa.com/manual',
  suppportUrl: 'http://common.laalsa.com/support/support',
  reportUrl: 'https://common.laalsa.com/reports',
  // baseUrl: 'http://localhost:3025',
  // visitPlannerUrl: 'http://10.9.50.78:3025/',
  visitPlannerUrl: 'https://salesplanner.yumzy.ai',
  // mapApiKey: 'AIzaSyBtpy4clGhnyx0-WpwlkatW01RYr476C_c',
  mapApiKey: 'AIzaSyBtpy4clGhnyx0-WpwlkatW01RYr476C_c',
  outletValidation: 'http://159.89.164.240:27017',
  // kshetraUrl: 'http://139.59.77.43:3023/kshetra',
  couponUrl: 'https://dev.so.yumzy.ai',
  addMenuitem:'https://dev02.laalsa.com'
}
