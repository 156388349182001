import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '../../services/common.service';
import { Router } from '@angular/router';
import { AppData, ToastService } from '../..';
import { OtpService } from '../../services/otp.service';
import { StorageService } from 'src/app/core/services/storage/storage.service';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit {
  otpForm: FormGroup;
  interval: any;
  timeLeft = 30;
  counter = 2;
  resendDisabled = false;
  resendTextHide = true;
  mobileNum: string;
  accountObj: any;
  constructor(private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private router: Router,
    private otpService: OtpService,
    public commonService: CommonService,
    public toasterService: ToastService,
    private storage: StorageService) {

    AppData.accountData.subscribe(obj => {
      if (obj !== null) {
        this.accountObj = obj;
        console.log(obj);
      }
    });
  }

  ngOnInit() {
    this.mobileNum = this.storage.getItem('mobileNum');
    //for otp form validation
    this.initForm();
    // for start resend otp timer
    this.onGetOtpTimer();
  }


  initForm() {
    this.otpForm = this.formBuilder.group({
      otp: ['', [Validators.required, Validators.minLength(4)]]
    });
  }

  verifyOtp() {
    const body = {
      "mobile": this.accountObj.mobile,
      "countryCode": "+91",
      "otp": this.otpForm.value.otp
    }
    if (this.accountObj.redirect === 'LOGIN') {
      this.onLogin(body);
    } else if (this.accountObj.redirect === 'SIGNUP') {
      body['email'] = this.accountObj.email;
      body['name'] = this.accountObj.name;
      this.onSignup(body);
    }
  }

  // for sign in
  onLogin(body) {
    this.otpService._login(body).then((data) => {
      this.router.navigate(['/dashboard']);
      this.activeModal.dismiss();
      console.log(data['body'].userData);
      AppData.userDataSubject.next(data['body'].userData);
    }).catch(err => {
      console.log(err);
      if (err['error']['message']) {
        this.toasterService.presentToast(err['error']['message'], '', 'error');
      }
    });
  }

  // for create account
  onSignup(body) {
    this.otpService._signUp(body).then((data) => {
      this.router.navigate(['/login']);
      this.activeModal.dismiss();
    }).catch(err => {
      console.log(err);
    });
  }

  // for start resend otp timer
  onGetOtpTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.resendDisabled = false;
        this.resendTextHide = true;
        this.timeLeft--;
      } else {
        if (this.timeLeft === 0) {
          this.resendDisabled = true;
          this.resendTextHide = false;
          clearInterval(this.interval);
          if (this.counter === 0) {
            this.resendDisabled = false;
            this.resendTextHide = false;
          }
        }
      }
    }, 1000);
  }

  // for resend otp 
  resendOtp() {
    const body = {
      "method": "resendOtp",
      "countryCode": "+91",
      "mobile": this.accountObj.mobile,
      "appName": "yumzy_review_panel"
    }
    this.otpService._sendOtp(body).then((data) => {
      console.log(data);
      this.timeLeft = 30;
      if (this.counter > 0) {
        this.onGetOtpTimer();
        this.counter--;
      }
      this.toasterService.presentToast(data['body']['message'], 'Success', 'success');
    }).catch((err) => {
      console.log(err);
    });
  }

  //
  dismiss(): void {
    if (this.activeModal)
      this.activeModal.dismiss();
  }
}
