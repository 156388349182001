import { AppData } from './app-data.service';

/**
 * Type URL_KEYS for restricting the url entered
 */
export type URL_KEYS =

  // Accounts Related URLs
  'login' | 'signup' | 'sendOtp' | 'completeSignup' | 'forgetPassword' |

  // python services
  'outlets' | 'postOutletList' | 'restaurantdate' | 'cityData' | 'firstRestaurant' | 'generateRoute' |
  'updateRestaurantStatus' | 'newRestaurant' | 'addRestaurant' | 'pdfUpload' | 'newRestData' |
  'fetchNewRestDetails' | 'removeImage' | 'removeOper' | 'updateOper' | 'getParentList' |

  //  outlet Details
  'imageDetails' | 'updateImageDetails' | 'outletList' | 'setOutletAvailability' | 'getOutletAvailability' |
  'getAllOutletsWithLatLng' | 'audit' | 'menuDelete' | 'getAllLocalityList' | 'outletIdName' |
  'getOutletsList' | 'getActiveOffers' | 'getOutletGridData' | 'getOutlets' | 'getOutletOpHours' | 'goLiveUpdate'|

  // menu support
  'supportUserDetails' | 'RestaurantDetails' | 'linkAccount' | 'unlinkAccount' | 'markCompleteOrder' |

  // account migrate
  'accountMigrate' |

  // outlet validation
  'restNameLocation' |

  // promotion list;
  'promotionList' | 'promotionStatus' | 'promotionView' |
  'promotionDelete' | 'promotionSection' | 'promotionSectionList' |

  // promotion creation
  'localityList' | 'itemOutletList' | 'promotionCreation' |
  'promoCheckOffer' | 'promoSecDelete' | 'promoSecUpdate' |

  //  User Related Data
  'userSignupStatus' | 'userActivityStatus' | 'scratchCardInfo' |

  //kshetra list
  'fetchKshetra' | 'getCity' | 'kshetraUpdate' | 'kshetraList' | 'singlekshetraUpdate' |

  //Delivery list
  'deliveryStatusUpdate' | 'deliveryZones' | 'deliveryProviders' |

  // Sales Restaurant list
  'filterList' | 'getFilteredList' | 'generateXlsForRestList' |
  'deliveryStatusUpdate' | 'deliveryZones' | 'deliveryProviders'

  //coupon related
  | 'couponCreation' | 'couponList' | 'outletOrderStatus'

  // commercial update
  | 'commercialUpdate' | 'getCommercialDetails' |

  // bank details
  'getBankDetails' | 'bankDetailsUpdate' |

  // rest details
  'searchRestDetails' | 'fetchRestDetails' | 'getOutletCount' | 'updateOutletCount' | 'comments' | 'restVisitAppData' |
  'cuisinesData' | 'sendMail' | 'docSubmitted' | 'getCuisinesList' |

  // dashboard related data
  'getFilterAppData' | 'getAgreementDetailList' | 'getOnboardDetailList' | 'getReviewDetailList' |
  'updateAgreement' | 'updateOnboard' | 'updateOnboardStatus' | 'updateReviewStatus' | 'updateRestReview' |
  'cancelAgreement' | 'cancelOnboard' | 'cancelReview' | 'getOutletList' | 'getCommissionData' |
  'assignMenuEntryPerson' |

  // menu related excel data
  'fetchTypeList' |

  // account mapping
  'getMapRestDetails' | 'mapRestData' | 'changeAccountManager' |

  // order heat map
  'cityLocality' | 'timeWiseOrderCount' |
  //Outlet Promotion Updates
  'restDetails' | 'getOutletsListData' | 'getCityAllPromoList' | 'enrollPromo' | 'getPromoItemList' | 'themeValidation' | 'themeLeave' |
  'getAllRestDetails' | 'outletListForReplicateMenu' | 'replicateMenuItems' |

  // comments in outlet communication tracker
  'outletCommunicationComments' |

  // export in restaurant status
  'exportOutletStatus' |

  // to cancel refund
  'cancelRefund' |

  'walletCredit' | 'searchOutlet' | 'getSettleReport' | 'getOrderBreakup' | 'addMenuItem' |

  // paymentLink Generation
  'paymentLink'
  ;

const UrlMapping = {
  // Accounts Related URLs

  login: { url: AppData.baseUrl + '/account/login', showMsg: false },
  signup: { url: AppData.baseUrl + '/account/signup', showMsg: false },
  menuDelete: { url: AppData.baseUrl + '/outlet/outletMenuDelete', showMsg: false },
  sendOtp: { url: AppData.baseUrl + '/account/sendOtp', showMsg: false },
  outlets: { url: AppData.baseUrl + '/outlet/outletIdName', showMsg: false },
  audit: { url: AppData.baseUrl + '/outlet/updateAuditStatus', showMsg: false },
  imageDetails: { url: AppData.imageUrl + '/fetchimagedetails', showMsg: false },
  updateImageDetails: { url: AppData.imageUrl + '/fupdateimagedetails', showMsg: false },
  outletList: { url: AppData.outletMappingUrl + '/outlets', showMsg: false },
  postOutletList: { url: AppData.outletMappingUrl + '/outlet_ids', showMsg: false },
  setOutletAvailability: { url: AppData.baseUrl + '/outlet/setAvailablity', showMsg: true },
  getOutletAvailability: { url: AppData.baseUrl + '/outlet/getAvailablity', showMsg: false },
  outletIdName: { url: AppData.baseUrl + '/outlet/outletIdName', showMsg: false },
  getOutletsList: { url: AppData.baseUrl + '/outlet/outletDetailsList', showMsg: false },
  getAllLocalityList: { url: AppData.baseUrl + '/outlet/outletLocalityCount', showMsg: false },
  getAllOutletsWithLatLng: { url: AppData.baseUrl + '/outlet/outletLatLng', showMsg: false },
  getActiveOffers: { url: AppData.baseUrl + '/outlet/offerLocalityCount', showMsg: false },
  getOutletGridData: { url: AppData.baseUrl + '/outlet/outletGridData', showMsg: false },
  supportUserDetails: { url: AppData.suppportUrl + '/user/userInfo', showMsg: true },
  RestaurantDetails: { url: AppData.suppportUrl + '/user/restInfo', showMsg: true },
  linkAccount: { url: AppData.suppportUrl + '/user/linkAccount', showMsg: true },
  unlinkAccount: { url: AppData.suppportUrl + '/user/unlinkAccount', showMsg: true },
  restaurantdate: { url: AppData.visitPlannerUrl + '/assigned_rests/', showMsg: false },
  cityData: { url: AppData.visitPlannerUrl + '/cities', showMsg: false },
  firstRestaurant: { url: AppData.visitPlannerUrl + '/cities/rests/', showMsg: false },
  generateRoute: { url: AppData.visitPlannerUrl + '/cities/rests/route/', showMsg: false },
  updateRestaurantStatus: { url: AppData.visitPlannerUrl + '/assigned_rests/status_update', showMsg: false },
  accountMigrate: { url: AppData.baseUrl + '/accountmigration/account/migrate', showMsg: false },
  newRestaurant: { url: AppData.visitPlannerUrl + '/assigned_rests/status_update/add_rest', showMsg: false },
  restNameLocation: { url: AppData.outletValidation + '/restaurantnamelocation/', showMsg: false },
  promotionList: { url: AppData.baseUrl + '/promotion/getPromoList', showMsg: false },
  promotionStatus: { url: AppData.baseUrl + '/promotion/updatePromoStatus', showMsg: false },
  promotionDelete: { url: AppData.baseUrl + '/promotion/deletePromo/', showMsg: true },
  promotionView: { url: AppData.baseUrl + '/promotion/fetchById/', showMsg: false },
  localityList: { url: AppData.baseUrl + '/promotion/getLocalityList', showMsg: false },
  itemOutletList: { url: AppData.baseUrl + '/promotion/getItemOutlet', showMsg: false },
  promotionCreation: { url: AppData.baseUrl + '/promotion/', showMsg: true },
  promotionSection: { url: AppData.baseUrl + '/promotion/createSection', showMsg: true },
  promotionSectionList: { url: AppData.baseUrl + '/promotion/getSectionList', showMsg: false },
  promoCheckOffer: { url: AppData.baseUrl + '/promotion/checkOffer', showMsg: true },
  promoSecDelete: { url: AppData.baseUrl + '/promotion/deleteSection/', showMsg: true },
  promoSecUpdate: { url: AppData.baseUrl + '/promotion/updateSection', showMsg: true },
  couponCreation: { url: AppData.baseUrl + '/coupons/', showMsg: true },
  couponList: { url: AppData.baseUrl + '/coupons/getCouponsList', showMsg: false },
  fetchKshetra: { url: AppData.manualBaseUrl + '/kshetra/getUnProcessedOutlets', showMsg: false },
  getCity: { url: AppData.manualBaseUrl + '/kshetra/getCities', showMsg: false },
  kshetraUpdate: { url: AppData.manualBaseUrl + '/kshetra/updateMultipleOutlet', showMsg: false },
  kshetraList: { url: AppData.manualBaseUrl + '/kshetra/getStatusOfProcess', showMsg: false },
  singlekshetraUpdate: { url: AppData.manualBaseUrl + '/kshetra/markCompleted', showMsg: false },
  // userSignupStatus: { url: 'http://localhost:3025' + '/users/userSignupStatus', showMsg: false },
  userSignupStatus: { url: AppData.baseUrl + '/users/userSignupStatus', showMsg: false },
  scratchCardInfo: { url: AppData.baseUrl + '/users/scratchCardInfo', showMsg: false },
  // userActivityStatus: { url: 'http://10.9.50.166:3025' + '/users/userActivityStatus', showMsg: false },
  userActivityStatus: { url: AppData.baseUrl + '/users/userActivityStatus', showMsg: false },
  outletOrderStatus: { url: AppData.reportUrl + '/report/getDailyOutletOrderToYumzy', showMsg: false },
  commercialUpdate: { url: AppData.baseUrl + '/outlet/updateCommission', showMsg: false },
  getCommercialDetails: { url: AppData.baseUrl + '/outlet/fetchCommission', showMsg: false },
  getBankDetails: { url: AppData.baseUrl + '/outlet/fetchBankDetails', showMsg: false },
  bankDetailsUpdate: { url: AppData.baseUrl + '/outlet/updateBankDetails', showMsg: false },
  searchRestDetails: { url: AppData.baseUrl + '/restaurant/searchRestDetails', showMsg: false },
  fetchRestDetails: { url: AppData.baseUrl + '/restaurant/fetchRestDetails', showMsg: false },
  getOutletCount: { url: AppData.baseUrl + '/restaurant/getOutletCount', showMsg: false },
  updateOutletCount: { url: AppData.baseUrl + '/restaurant/updateOutletCount', showMsg: false },
  addRestaurant: { url: AppData.baseUrl + '/salesRestaurant/saveSalesRestDetails', showMsg: false },
  pdfUpload: { url: AppData.baseUrl + '/salesRestaurant/getSignedUrlAndSave', showMsg: false },
  newRestData: { url: AppData.baseUrl + '/salesRestaurant/searchSalesRestDetails', showMsg: false },
  fetchNewRestDetails: { url: AppData.baseUrl + '/salesRestaurant/getSalesRestDetails', showMsg: false },
  comments: { url: AppData.baseUrl + '/salesRestaurant/comments', showMsg: false },
  restVisitAppData: { url: AppData.baseUrl + '/salesRestaurant/getRestVisitAppData', showMsg: false },
  deliveryStatusUpdate: { url: AppData.baseUrl + '/delivery/updateOperational', showMsg: false },
  deliveryZones: { url: AppData.baseUrl + '/delivery/getDeliveryDetails', showMsg: false },
  deliveryProviders: { url: AppData.baseUrl + '/delivery/fetchOneRecord', showMsg: false },
  filterList: { url: AppData.baseUrl + '/salesRestaurant/genRestFilterData', showMsg: false },
  getFilteredList: { url: AppData.baseUrl + '/salesRestaurant/getSalesRestDetailList', showMsg: false },
  removeImage: { url: AppData.baseUrl + '/salesRestaurant/removeDocLink', showMsg: false },
  generateXlsForRestList: { url: AppData.baseUrl + '/salesRestaurant/generateXlsForRestList', showMsg: false },
  getFilterAppData: { url: AppData.baseUrl + '/salesRestaurant/getFilterAppData', showMsg: false },
  getAgreementDetailList: { url: AppData.baseUrl + '/salesRestaurant/agreement/getAgreementDetailList', showMsg: false },
  getOnboardDetailList: { url: AppData.baseUrl + '/salesRestaurant/onboard/getOnboardDetailList', showMsg: false },
  getReviewDetailList: { url: AppData.baseUrl + '/salesRestaurant/review/getReviewDetailList', showMsg: false },
  updateAgreement: { url: AppData.baseUrl + '/salesRestaurant/agreement/updateAgreement', showMsg: false },
  updateOnboard: { url: AppData.baseUrl + '/salesRestaurant/onboard/updateOnboard', showMsg: false },
  assignMenuEntryPerson: { url: AppData.baseUrl + '/salesRestaurant/onboard/assignMenuEntryPerson', showMsg: true },
  updateOnboardStatus: { url: AppData.baseUrl + '/salesRestaurant/onboard/updateStatus', showMsg: false },
  updateReviewStatus: { url: AppData.baseUrl + '/salesRestaurant/review/updateStatus', showMsg: false },
  updateRestReview: { url: AppData.baseUrl + '/salesRestaurant/review/updateReview', showMsg: false },
  cancelAgreement: { url: AppData.baseUrl + '/salesRestaurant/agreement/cancelAgreement', showMsg: false },
  cancelOnboard: { url: AppData.baseUrl + '/salesRestaurant/onboard/cancelOnboard', showMsg: false },
  cancelReview: { url: AppData.baseUrl + '/salesRestaurant/review/cancelReview', showMsg: false },
  cuisinesData: { url: AppData.baseUrl + '/salesRestaurant/restaurant/getCuisines', showMsg: false },
  sendMail: { url: AppData.baseUrl + '/salesRestaurant/restaurant/sendForAgreement', showMsg: false },
  docSubmitted: { url: AppData.baseUrl + '/salesRestaurant/restaurant/checkAllDocSubmitted', showMsg: false },
  removeOper: { url: AppData.baseUrl + '/salesRestaurant/restaurant/deleteOpHours', showMsg: false },
  updateOper: { url: AppData.baseUrl + '/salesRestaurant/restaurant/updateOpHours', showMsg: false },
  getCuisinesList: { url: AppData.baseUrl + '/salesRestaurant/restaurant/getCuisines', showMsg: false },
  fetchTypeList: { url: AppData.baseUrl + '/promotion/fetchTypeList', showMsg: false },
  paymentLink: { url: AppData.baseUrl + '/finance/pay/paytm/generateLink', showMsg: false },

  getMapRestDetails: { url: AppData.baseUrl + '/salesRestaurant/mapping/getMapRestDetails', showMsg: false },
  mapRestData: { url: AppData.baseUrl + '/salesRestaurant/mapping/mapRestData', showMsg: true },
  changeAccountManager: { url: AppData.baseUrl + '/salesRestaurant/mapping/changeAccountManager', showMsg: true },
  timeWiseOrderCount: { url: AppData.baseUrl + '/delivery/localitytimewiseordercount', showMsg: false },
  cityLocality: { url: AppData.baseUrl + '/delivery/getCityLocality', showMsg: false },
  getOutletList: { url: AppData.baseUrl + '/finance/getOutletList', showMsg: false },
  getCommissionData: { url: AppData.baseUrl + '/finance/getComissionData', showMsg: false },
  getParentList: { url: AppData.baseUrl + '/salesRestaurant/restaurant/searchParentRestDetails', showMsg: false },
  restDetails: { url: AppData.baseUrl + '/restaurant/searchRestDetails', showMsg: false },
  getOutletsListData: { url: AppData.baseUrl + '/outlet/getOutletList', showMsg: false },
  getCityAllPromoList: { url: AppData.baseUrl + '/promotion/getOutletPromoList', showMsg: false },
  enrollPromo: { url: AppData.baseUrl + '/promotion/enrollPromo', showMsg: false },
  getPromoItemList: { url: AppData.baseUrl + '/promotion/getPromoItemList', showMsg: false },
  themeValidation: { url: AppData.baseUrl + '/promotion/validatePromo', showMsg: false },
  themeLeave: { url: AppData.baseUrl + '/promotion/rollback', showMsg: false },

  getAllRestDetails: { url: AppData.baseUrl + '/restaurant/getAllRestDetails', showMsg: false },
  outletListForReplicateMenu: { url: AppData.baseUrl + '/outlet/getOutletList', showMsg: false },
  replicateMenuItems: { url: AppData.baseUrl + '/outlet/replicateOutletData', showMsg: false },
  getOutlets: { url: AppData.baseUrl + '/restComm/searchOutletDetails', showMsg: false },
  getOutletOpHours: { url: AppData.baseUrl + '/restComm/getOutletAllDetails', showMsg: false },
  outletCommunicationComments: { url: AppData.baseUrl + '/restComm/saveOutletDetails', showMsg: false },
  exportOutletStatus: { url: AppData.baseUrl + '/outlet/exportOutletStatus', showMsg: false },
  markCompleteOrder: { url: AppData.baseUrl + '/delivery/markForComplete', showMsg: true },
  cancelRefund: { url: AppData.baseUrl + '/delivery/resumeRefund', showMsg: false },
  walletCredit: { url: AppData.baseUrl + '/wallet/credit', showMsg: true },

  searchOutlet: { url: AppData.baseUrl + '/outlet/searchOutlet', showMsg: false },
  goLiveUpdate: { url: AppData.baseUrl + '/outlet/goLiveUpdate', showMsg: true },
  getSettleReport: { url: AppData.reportUrl + '/report/getSettleReport', showMsg: false },
  getOrderBreakup: { url: AppData.reportUrl + '/report/getOrderBreakup', showMsg: false },
  addMenuItem: { url: AppData.addMenuitem + '/addMenuItem/' , showMsg: false },
};

/**
 * add url here for generating urls, all url entry is restricted to above mentioned URL_KEYS
 */

export class RequestUrl {
  static get(key: URL_KEYS): any {
    return UrlMapping[key];
  }
}
