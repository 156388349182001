import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { StorageService } from 'src/app/core/services/storage/storage.service';
import { AppData } from '../..';
import { first } from 'rxjs/operators';
import { ExcelExportService } from '../../services/excel-export.service';

@Component({
  selector: 'app-restaurant-list',
  templateUrl: './restaurant-list.component.html',
  styleUrls: ['./restaurant-list.component.scss']
})
export class RestaurantListComponent implements OnInit, OnDestroy {
  weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  outletWise;
  outletName;
  restaurentObjKeys = [
    { displayName: 'Outlet Name', keyName: 'outletName', width: '225px' },
    // {displayName: 'Outlet Name', keyName: 'displayName', width: '20%' },
    { displayName: 'Status', keyName: 'status', width: '75px' },
    { displayName: 'Timings', keyName: 'operationalHours', width: '375px' },
    { displayName: 'Available', keyName: 'available', width: '200px' },
    { displayName: 'Menu', keyName: 'menuCount', width: '200px' },
    { displayName: 'Offer Details', keyName: 'offerData', width: '555px' }
  ];
  localityName;
  // slotDetails;
  endDateString;
  startDateString;

  constructor(
    private storageService: StorageService,
    private excelService: ExcelExportService
  ) {
    console.log("constructor");
    AppData.individualRestaurantInfo.subscribe((data) => {
      console.log("calling subscriber");
      console.log("data", data);
      if (data !== null && data.length > 0) {
        this.outletName = this.storageService.getItem('OutletName');
        console.log("outletName", this.outletName);
        this.outletWise = JSON.parse(JSON.stringify(data));
        this.formatTimingsAndOffers(this.outletWise);
      } else {
        console.log(data);
        this.outletWise = [];
        this.outletName = '';
      }
      console.log(this.outletWise);
    });
  }

  ngOnInit() {

  }

  formatTimingsAndOffers(outletWise) {
    console.log("outletwise data--->", outletWise);
    const formattedDetails = {};
    outletWise.forEach((slotDetails, mainIndex) => {
      // this.slotDetails = slotDetails;
      let startDateString;
      let endDateString;
      slotDetails.operationalHours.forEach((operationalTime, index) => {
        let weekdayString = '';
        if (operationalTime.days !== undefined) {
          operationalTime.days.forEach((day, j) => {
            if (operationalTime.days) {
              weekdayString += this.weekdays[j] + ',' + ' ';
            }
          })
        }
        formattedDetails['weekdaysString'] = weekdayString.replace(/,\s*$/, '');
        if (operationalTime['startTime'] !== null && operationalTime['startTime'] !== undefined) {
          startDateString = this.processTimeString(operationalTime['startTime']);
        }
        if (operationalTime['endTime'] !== null && operationalTime['endTime'] !== undefined) {
          endDateString = this.processTimeString(operationalTime['endTime']);
        }
        formattedDetails['timeSlot'] = startDateString + '-' + endDateString;
        if (operationalTime['prepTime'] !== null && operationalTime['prepTime'] !== undefined) {
          formattedDetails['prepTime'] = operationalTime['prepTime'];
        }
        let timingsData = ' Days : ' + formattedDetails['weekdaysString'] + '\n' + ' Timeslot : ' +
          formattedDetails['timeSlot'] + '\n' + ' PreparationTime : ' + formattedDetails['prepTime'] + '\n';
        console.log(timingsData);
        outletWise[mainIndex].operationalHours[index] = timingsData;
        console.log('formated details----->', formattedDetails);

      });
      let offer = '';
      if (slotDetails.offerData.length > 0) {
        slotDetails.offerData.forEach((offerData, offerIndex) => {
          offer = ' Description :' + offerData.description + '\n' + ' OfferType : ' +
            offerData.OfferType + '\n' + ' Duration : ' + offerData.Duration + '\n';
          outletWise[mainIndex].offerData[offerIndex] = offer;
        });
      }
    });
  }

  formattedTime(operationalTime) {
    let startDateString;
    let endDateString;
    if (operationalTime['startTime'] !== null && operationalTime['startTime'] !== undefined) {
      startDateString = this.processTimeString(operationalTime['startTime']);
    }
    if (operationalTime['endTime'] !== null && operationalTime['endTime'] !== undefined) {
      endDateString = this.processTimeString(operationalTime['endTime']);
    }
    return startDateString + '-' + endDateString;
  }

  processTimeString(timeString) {
    console.log("---->timeString", timeString);
    let startDateHourString = timeString.substring(0, 2);
    const startDateMinString = timeString.substring(2, 4);
    let startDateAmPmString = 'AM';
    if (Number(startDateHourString) === 12) {
      startDateAmPmString = 'PM';
    } else if (Number(startDateHourString) > 12) {
      startDateHourString = Number(startDateHourString) - 12;
      startDateAmPmString = 'PM';
    } else if (Number(startDateHourString) > 24) {
      startDateHourString = Number(startDateHourString) - 24;
      startDateAmPmString = 'AM';
    }
    return (startDateHourString + ':' + startDateMinString + ' ' + startDateAmPmString);
  }

  ngOnDestroy() {
    this.storageService.removeItem('OutletName');
    AppData.individualRestaurantInfo.next(null);
    // AppData.individualRestaurantInfo.unsubscribe();
  }

  exportAsXLSX(): void {
    let dummyArr = [];
    let displayData = {};

    this.outletWise.forEach((element) => {
      this.restaurentObjKeys.forEach((restObjKey) => {
        let displayName = restObjKey.displayName;

        if (displayData.hasOwnProperty(displayName)) {
          if (displayName == "Timings") {
            displayData['Timings'] = (element[restObjKey.keyName]).toString();
          } else {
            displayData = {
              [restObjKey.displayName]: element[restObjKey.keyName]
            }
          }

          if (displayName == "Offer Details") {
            displayData['Offer Details'] = (element[restObjKey.keyName]).toString();
          }
        } else {
          if (displayName == "Timings") {
            displayData['Timings'] = (element[restObjKey.keyName]).toString();
          } else {
            displayData[displayName] = element[restObjKey.keyName];
          }

          if (displayName == "Offer Details") {
            displayData['Offer Details'] = (element[restObjKey.keyName]).toString();
          }
        }

      })
      dummyArr.push(displayData);

    })

    console.log(dummyArr);

    this.excelService.exportAsExcelFile(dummyArr, 'Yumzy report_' + this.outletName);

    // this.excelService.exportAsExcelFile(this.outletWise, 'Yumzy report_' + this.outletName);
  }

}
