import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SalesRestaurantDashboardService } from 'src/app/modules/support/sales-restaurant-dashboard/service/sales-restaurant-dashboard.service';


@Component({
  selector: 'app-onboarding-status',
  templateUrl: './onboarding-status.component.html',
  styleUrls: ['./onboarding-status.component.scss']
})
export class OnboardingStatusComponent implements OnInit {
  @Input() public inputData;
  @Input() public restId;
  @Input() public type;
  @Output() emitData: EventEmitter<any> = new EventEmitter();
  onboardStatusListKeys;
  optionsMap = {};
  constructor(
    public activeModal: NgbActiveModal,
    private dashboardService: SalesRestaurantDashboardService
  ) { }

  ngOnInit() {
    this.onboardStatusListKeys = Object.keys(this.inputData);

    this.optionsMap = JSON.parse(JSON.stringify(this.inputData));
  }

  close() {
    this.activeModal.close();
  }

  submit() {
    var objData = {
      "restId": this.restId,
    }

    if (this.type === 'Onboarding') {
      objData["onboardingStatus"] = this.optionsMap;
    } else {
      objData['reviewStatus'] = this.optionsMap;
    }

    this.dashboardService
      .updateOnboardStatus(objData, this.type)
      .then((res) => {
        this.activeModal.close();

        this.emitData.emit('triggered');
      })
  }

  updateCheckedOptions(key, event) {
    this.optionsMap[key] = event.target.checked;
  }
}
