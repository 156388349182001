import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModalOptions, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  private modalRef: NgbModalRef;
  closeResult: string;
  constructor(
    private router: Router,
    private authService: AuthService,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
  }
  logout() {
    this.authService.logout();
    this.modalRef.close();
    //  this.router.navigate(['/login']);
  }
  dashboard() {
    this.router.navigate(['/dashboard/home']);
  }
  logoutPopup(content) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    };
    this.modalRef = this.modalService.open(content, ngbModalOptions);
    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
