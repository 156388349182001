import { Injectable } from '@angular/core';
import { ApiRequestService } from '../../services/api-request.service';

@Injectable({
  providedIn: 'root'
})
export class OutletSearchService {

  constructor(private apiRequestService: ApiRequestService) { }

  public getSearchOutlet(txt) {
    return this.apiRequestService.get('searchOutlet', '?text=' + txt);
  }
}
